<template>
  <div class="text-center landing">
      <v-row>
          <v-col cols="12" style="    padding: 30% 10%; height:100vh; background:#0000008f; ">
            <img style="width:15%; position:absolute; top:40px; left:40px;" src="../assets/amara_logo_white.png" alt="image">
            <div>
              <div class="align-center btn-bg" style="padding: 35px; border-radius:10px; ">
                <h3 style="font-size:40px; color:white; padding-bottom:10px;">Welcome back</h3>
                <button class="gsi-material-button" @click="AuthProvider('google')">
                  <div class="gsi-material-button-state"></div>
                  <div class="gsi-material-button-content-wrapper">
                    <div class="gsi-material-button-icon">
                      <v-progress-circular v-if="showLoader"
                        indeterminate :size="20"
                        color="primary"
                      ></v-progress-circular>
                      <svg v-else version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </svg>
                    </div>
                    <span class="gsi-material-button-contents">Sign in with Google</span>
                  </div>
                </button>
                <!-- <v-btn  id="customBtn" class="customGPlusSignIn cursor-pointer ggl-btn" style="color: #fff !important;" @click="connectWith('google')" outlined color="#050550">Sign in with Google</v-btn> -->
              </div>
            </div>
          </v-col>
      </v-row>
  </div>
</template>

<script>
/*eslint-disable */
import Vue from 'vue';
import axios from 'axios';
import VueAuthenticate from 'vue-authenticate';
import { mapState } from 'vuex';
Vue.prototype.$http = axios;
Vue.use(VueAuthenticate, {
  baseUrl: `${process.env.VUE_APP_API_URL_FB}`,
  tokenType: 'JWT',
  providers: {
    google: {
      clientId: '896455913326-uk7r10kn2qj1ll74pnqngr4qh395gsa3.apps.googleusercontent.com',
      redirectUri: window.location.origin, // Specify your redirect URI
    }
  }
});

export default {
    components: {
        axios,
    },
    name: 'Login',
    data: () => ({
        loading: false,
        showLoader: false,
        forceDialog: true,
    }),
    computed: {
    ...mapState({
      snackbar: state => state.snackbar,
      user: state => state.user
    })
  },
  beforeMount () {
    if (localStorage.getItem('CHATBOT_USER')) {
      this.$router.push('/');
    } else {
      sessionStorage.clear();
      localStorage.clear();
    }
    this.handleOAuthResponse();
  },
    methods: {
    handleOAuthResponse() {
      // Extract the access token from the URL fragment
      console.log(window.location);
      const hashParams = new URLSearchParams(this.$route.query.redirect.substring(1));
      const accessToken = hashParams.get('access_token');
      if (accessToken) {
        // Use the access token to get user info or authenticate with your backend
        this.showLoader = true;
        this.SocialLogin(accessToken);
      }
    },
    AuthProvider() {
      const clientId = '896455913326-uk7r10kn2qj1ll74pnqngr4qh395gsa3.apps.googleusercontent.com';
      const redirectUri = window.location.origin; // or specify your exact redirect URI if it's different
      const scope = 'email profile';
      const responseType = 'token';

      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&include_granted_scopes=true`;

      // Redirect the user to the Google login page
      window.location.href = authUrl;
    },
    // AuthProvider(provider) {
    //   var self = this;

    //   this.$auth
    //     .authenticate(provider)
    //     .then((response) => {
    //       console.log(response);
    //       self.SocialLogin(provider, response);
    //     })
    //     .catch((err) => {
    //       console.log({ err: err });
    //     });
    // },
    SocialLogin(response) {
      axios.post(`${process.env.VUE_APP_API_URL_FB}mfa/social_auth/google/`, {
        access_token: response
      }).then((response) => {
        if (response) {
          this.$store.dispatch('createSession', response.data);
          if (response.data && response.status === 200) {
            this.loading = false;
            this.$store.dispatch('createSession', response.data);
            this.userData = response.data;
            this.$router.push('/');
          }
          // if (response.data.company) {
          //   this.getAlertWords();
          // }
          // if (this.$route.query.redirect && this.$route.query.redirect !== undefined) {
          //   this.$router.push(this.$route.query.redirect);
          // } else {
          //   if (response.data.milestone_active) {
          //       this.$router.push('/');
          //       this.$store.dispatch('dashboardViewUpdate', 'culture');
          //       localStorage.setItem('DASHBOARD_VIEW', JSON.stringify('culture'));
          //     } else if (response.data.adhoc_active) {
          //       this.$router.push('/ad-hoc');
          //       this.$store.dispatch('dashboardViewUpdate', 'adhoc');
          //       localStorage.setItem('DASHBOARD_VIEW', JSON.stringify('adhoc'));
          //     } else {
          //       this.$router.push('/');
          //     }
          // }
          setTimeout(() => {
            this.showLoader = false;
          }, 1000);
        }
      }).catch((err) => {
        this.loading = false;
        if (err.response && err.response.status === 400 && err.response.data.error) {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: err.response.data.error
          });
        } else if (err.response && err.response.status === 400 && err.response.data.non_field_errors) {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: err.response.data.non_field_errors[0]
          });
        } else {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Some error occurred, Please try again later!'
          });
        }
      });
    },
    connectWith (provider) {
      sessionStorage.clear();
      localStorage.clear();
      this.$auth.authenticate(provider).then((response) => {
        if (response.data && response.status === 200) {
          this.loading = false;
          this.$store.dispatch('createSession', response.data);
          this.userData = response.data;
          this.$router.push('/');
        }
      }).catch((err) => {
        if (err.response && err.response.status === 400 && err.response.data.error) {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: err.response.data.error
          });
        } else if (err.response && err.response.status === 400 && err.response.data.non_field_errors) {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: err.response.data.non_field_errors[0]
          });
        } else {
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Some error occurred, Please try again later!'
          });
        }
      });
    },
    }
}
</script>
<style lang="scss">
.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state, 
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  // -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  // box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  // background-color: #303030;
  // opacity: 8%;
}
.landing {
  overflow: hidden;
  background: url('../assets/background.jpg');
background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
}
.btn-bg {
      background: #ffffff66;
          width: 32%;
    height: 200px;
    position: absolute;
    top: 40%;
    left: 10%;
}
</style>